import React from 'react';
import * as ContextMenu from '@radix-ui/react-context-menu';
import {
  DotFilledIcon,
  CheckIcon,
  ChevronRightIcon,
} from '@radix-ui/react-icons';
import './styles.css';

import { DataContext } from '../DataProvider';
import { UserContext } from '../UserProvider';
import AuthDialog from '../AuthDialog';

const ContextMenuDemo = ({ children }) => {
  const { selectedSong, deleteSong } = React.useContext(DataContext);
  const { token } = React.useContext(UserContext);
  if (token === null) {
    return (
      <>
        <AuthDialog />
      </>
    );
    // return <span>Hello World!</span>;
  }

  return (
    <ContextMenu.Root modal={false}>
      <ContextMenu.Trigger className='ContextMenuTrigger' disabled={false}>
        {children}
      </ContextMenu.Trigger>
      <ContextMenu.Portal>
        <ContextMenu.Content
          className='ContextMenuContent'
          sideOffset={5}
          align='end'
        >
          <ContextMenu.Sub>
            <ContextMenu.SubTrigger className='ContextMenuSubTrigger'>
              Add To Playlist
              <div className='RightSlot'>
                <ChevronRightIcon />
              </div>
            </ContextMenu.SubTrigger>
            <ContextMenu.Portal>
              <ContextMenu.SubContent
                className='ContextMenuSubContent'
                sideOffset={2}
                alignOffset={-5}
              >
                <ContextMenu.Item className='ContextMenuItem'>
                  New Playlist
                </ContextMenu.Item>
                <ContextMenu.Separator className='ContextMenuSeparator' />
                <ContextMenu.Item className='ContextMenuItem'>
                  Chill Electronica
                </ContextMenu.Item>
                <ContextMenu.Item className='ContextMenuItem'>
                  Hip Hop
                </ContextMenu.Item>
                <ContextMenu.Item className='ContextMenuItem'>
                  MLove
                </ContextMenu.Item>
              </ContextMenu.SubContent>
            </ContextMenu.Portal>
          </ContextMenu.Sub>

          <ContextMenu.Separator className='ContextMenuSeparator' />

          <ContextMenu.Item
            className='ContextMenuItem'
            onSelect={() => addToShortList(selectedSong, true)}
          >
            Play Next
          </ContextMenu.Item>
          <ContextMenu.Item
            className='ContextMenuItem'
            onSelect={() => addToShortList(selectedSong, false)}
          >
            Play Later
          </ContextMenu.Item>
          <ContextMenu.Separator className='ContextMenuSeparator' />
          <ContextMenu.Item className='ContextMenuItem'>
            Song Info
          </ContextMenu.Item>
          <ContextMenu.Separator className='ContextMenuSeparator' />
          <ContextMenu.Item
            className='ContextMenuItem'
            onSelect={() => deleteSong(selectedSong)}
          >
            Delete From Library
          </ContextMenu.Item>
        </ContextMenu.Content>
      </ContextMenu.Portal>
    </ContextMenu.Root>
  );
};

export default ContextMenuDemo;
